/* ==========================================================================
   Block elements
   ========================================================================== */

blockquote,
q {
  background: $c-grey-light;
  border-left: 4px solid $c-secondary;
  color: $c-black;
  font-family: $ff-primary;
  font-style: normal;
  padding: 1em;
  quotes: none;

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

/* Tables
   ========================================================================== */

table {
  border-collapse: collapse;
  border-spacing: 0;
}
