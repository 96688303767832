/* ==========================================================================
   Base styles
   ========================================================================== */

html,
body {
  box-sizing: border-box;
  color: $c-black;
  font-family: $ff-primary;
  font-size: $global-font-size;
  margin: 0;
  padding: 0;
}

.breadcrum{
  margin: 15px 0;
}