/* ==========================================================================
   Form elements
   ========================================================================== */

input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
select,
textarea {
  color: $c-black;
}

select {
  border: 1px solid $c-black;
  border-radius: 0;
}
