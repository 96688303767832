/*
material liner stepper related CSS for product selection page
*/
.without-mat-line {
  .mat-stepper-vertical-line::before {
    border-left-width: 0 !important;
  }
  .mat-step-label {
     min-width: 100%;
    white-space: unset;
  }
}
