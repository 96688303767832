/* ==========================================================================
   Vertical Rhythm
   ========================================================================== */

/**
  * Establishing a typographic system with modular scale & vertical rhythm.
  *
  * Margin calculations below came from https://www.gridlover.net/try using a Font Size of 16px, Line Height of 1.75 (28),
  * and Scale Factor of 1.125 all set in the variables in ./abstracts/_variables.scss.
  */
blockquote,
.blockquote {
  @include rhythm-desktop(0, 1, 1);
}

h1 {
  @include rhythm-desktop(8, 1, 1);
}

h2 {
  @include rhythm-desktop(6, 1, 0);
}

h3 {
  @include rhythm-desktop(4, 1, 0);
}

h4 {
  @include rhythm-desktop(1, 1, 0);
}

h5 {
  @include rhythm-desktop(0, 1, 0);
}

h6 {
  @include rhythm-desktop(-1, 1, 0);
}

dl,
p,
ul,
ol,
pre,
table {
  @include rhythm-desktop(0, 0, 1);

  &.top-margin {
    @include rhythm-desktop(0, 1, 1);
  }
}

dl dd,
dl dt,
ul ul,
ol ol,
ul ol,
ol ul,
li {
  @include rhythm-desktop(0, 0, 0);
}

/* Let's make sure all's aligned
   ========================================================================== */
b,
i,
strong,
em,
small,
code {
  line-height: 0;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Mobile Modifications
   ========================================================================== */
@media (max-width: $bp-mobile-max) {
  blockquote,
  .blockquote {
    @include rhythm-mobile(0, 1, 1);
  }

  h1 {
    @include rhythm-mobile(8, 1, 1);
  }

  h2 {
    @include rhythm-mobile(6, 1, 0);
  }

  h3 {
    @include rhythm-mobile(4, 1, 0);
  }

  h4 {
    @include rhythm-mobile(1, 1, 0);
  }

  h5 {
    @include rhythm-mobile(0, 1, 0);
  }

  h6 {
    @include rhythm-mobile(-1, 1, 0);
  }

  dl,
  p,
  ul,
  ol,
  pre,
  table {
    @include rhythm-mobile(0, 0, 1);

    &.top-margin {
      @include rhythm-mobile(0, 1, 1);
    }
  }

  dl dd,
  dl dt,
  ul ul,
  ol ol,
  ul ol,
  ol ul,
  li {
    @include rhythm-mobile(0, 0, 0);
  }
}
