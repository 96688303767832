/* ==========================================================================
   Overrides to accommodate styles inherited from the WordPress Theme Avada Fusion
   ========================================================================== */

// Avada sets overflow to none and takes over scrolling otherwise
html.no-overflow-y {
  overflow-y: auto;
}

// Avada Fusion sets collapse to display none, breaking Bootstrap accordions
.collapse {
  display: inherit;
}
